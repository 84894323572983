<template>
  <div class="h-100 d-flex flex-column">
    <div class="flex-grow-1 overflow-hidden d-flex flex-column">
      <div class="link-group-wrap d-flex align-items-center">
        <link-group :loading="loading"/>
        <portal-target name="screener-header" multiple/>

        <CButton
          v-tooltip="{
            content: $t('screener.playPauseBtn'),
            container: '.c-app',
          }"
          :color="isPaused ? 'warning' : 'primary'"
          :disabled="loading"
          class="button-play shadow-none"
          @click="isPaused = !isPaused"
        >
          <CIcon
            :name="isPaused ? 'cil-media-play' : 'cil-media-pause'"
            class="m-0"
          />
        </CButton>

        <v-select-wrap
          class="ml-4 flex-grow-1"
          placeholder="Ticker"
          v-model="settings.selectedPairs"
          :coin-list="sortedPairs"
          :futures-list="settings.futuresList"
          :watch-lists="watchLists"
          :search-keys="['label', 'labelAlt']"
          :reset-watch-list="openAttentionModal"
          :is-futures="isFuturesActive"
          @dropdownIsOpened="getFreshWatchLists"
        />
      </div>

      <CCard class="overflow-hidden flex-grow-1 d-flex m-0">
        <CCardBody class="c-card-body d-flex flex-grow-1">
          <transition name="fade" mode="out-in">
            <router-view
              ref="page"
              :settings="settings"
              :isPaused="isPaused"
              @changeLoading="loading = $event"
              @openGraph="openGraphHandler"
              @closeGraph="closeGraphHandler"
            />
          </transition>
        </CCardBody>
      </CCard>
    </div>

    <CModal
      :title="$t('screener.deleteWatchlistModal.title', { name: watchItemForRemove && watchItemForRemove.name ? watchItemForRemove.name : '' })"
      centered
      :show="!!watchItemForRemove"
      @update:show="closeAttentionModal"
    >
      <p>{{ $t('screener.deleteWatchlistModal.subtitle') }}</p>

      <template v-slot:footer>
        <div class="w-100">
          <CLoadingButtonCustom
            color="primary"
            class="mr-2"
            :loading="loadingRemoveList"
            :disabled="loadingRemoveList"
            @click.native="resetWatchList"
          >
            {{ $t('general.yes') }}
          </CLoadingButtonCustom>
          <CButton
            color="dark"
            @click="closeAttentionModal"
          >
            {{ $t('general.no') }}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import settings from '@/assets/js/screenerSettings'
import axios from "axios";
import VSelectWrap from "@/components/screener/VSelectWrap.vue";
import LinkGroup from "@/components/screener/LinkGroup";
import {mapActions} from "vuex";
import hideSupportMixin from "@/mixins/hideSupportMixin"
import {sortByAlphabet} from "@/assets/js/helpers";
import {createWatcherExhangeAndScreen} from "@/mixins/watch-exhange-and-screen";
import {
  SCREENER_PAGE_FUTURES
} from "@/shared/constants";

export default {
  name: "Screener",
  components: {
    VSelectWrap,
    LinkGroup
  },
  mixins: [
    hideSupportMixin,
    createWatcherExhangeAndScreen((context) => {
      context.getSettings()
      context.mapSelectedPairsForFutures()
    }),
  ],
  data() {
    return {
      settings: settings,
      loading: false,
      watchItemIndex: null,
      loadingRemoveList: false,
      loadingGetWatchList: false,
      isPaused: false,
      isPausedFromGraph: false
    }
  },
  watch: {
    isPaused(val) {
      this.$store.commit('setLineRunnerPaused', val)
    }
  },
  computed: {
    sortedPairs() {
      return sortByAlphabet(this.settings.pairs, 'label')
    },
    watchItemForRemove() {
      return this.isFuturesActive
        ? this.settings.watchListsBinancePerp[this.watchItemIndex]
        : this.settings.watchLists[this.watchItemIndex]
    },
    isFuturesActive() {
      return this.currentScreenAndExchange.screen === SCREENER_PAGE_FUTURES
    },
    watchLists() {
      return this.isFuturesActive ? this.settings.watchListsBinancePerp : this.settings.watchLists
    },
  },
  mounted() {
    this.getSettings()
    this.clearSelectedPairs()
    this.mapSelectedPairsForFutures()
  },
  methods: {
    mapSelectedPairsForFutures () {
      this.settings.selectedPairs.forEach(pair => {
        const label = pair.label.replace(/\.P|USDT/g, '');
        const ticker = pair.ticker.replace(/\.P|USDT/g, '');

        pair.label = label + (this.isFuturesActive ? 'USDT.P' : 'USDT');
        pair.ticker = ticker + (this.isFuturesActive ? 'USDT.P' : 'USDT');
      })
    },
    closeGraphHandler() {
      if (this.$store.state.settings?.screener?.pauseWhenOpeningChart && this.isPausedFromGraph) {
        this.isPaused = false
        this.isPausedFromGraph = false
      }
    },
    openGraphHandler() {
      if (this.$store.state.settings?.screener?.pauseWhenOpeningChart && !this.isPaused) {
        this.isPaused = true
        this.isPausedFromGraph = true
      }
    },
    ...mapActions({
      getUserWatchList: 'user/getUserSettings',
    }),
    getSettings() {
      const getURL = this.isFuturesActive
        ? `/api/v3/${this.currentScreenAndExchange.exchange}_perp/screener/settings`
        : '/api/v1/screener/settings';

      axios.get(getURL)
        .then(res => {
          this.settings.setPairs(res?.data?.pairs)
          this.settings.setTPairs(res?.data?.tPairs)

          const tablesIntervals = res?.data?.tablesIntervals
          if (tablesIntervals && Array.isArray(tablesIntervals) && tablesIntervals.length) {
            this.settings.setTablesIntervals(tablesIntervals)
          }

          const graphsPeriods = res?.data?.graphsPeriods
          if (graphsPeriods && Array.isArray(graphsPeriods)) {
            this.settings.setGraphsPeriods(graphsPeriods)
          }

          const trendsPeriods = res?.data?.trendsPeriods
          if (trendsPeriods && Array.isArray(trendsPeriods)) {
            this.settings.setTrendsPeriods(trendsPeriods)
          }
        })
        .catch(() => {
          return {}
        })
    },
    getFreshWatchLists() {
      this.loadingGetWatchList = true
      this.getUserWatchList()
        .finally(() => {
          this.loadingGetWatchList = false
        })
    },
    openAttentionModal(index) {
      this.watchItemIndex = index
    },
    closeAttentionModal() {
      this.watchItemIndex = null
    },
    resetWatchList() {
      if (!this.watchItemForRemove) return

      if (this.isFuturesActive) {
        this.settings.setWatchListsBinancePerpItem(this.watchItemIndex, {
          ...this.watchItemForRemove,
          pairsString: '',
          pairs: []
        })
      } else {
        this.settings.setWatchListsItem(this.watchItemIndex, {
          ...this.watchItemForRemove,
          pairsString: '',
          pairs: []
        })
      }

      this.loadingRemoveList = true;
      (this.isFuturesActive ?
        this.settings.promiseSaveWatchBinancePerpImmediately() :
        this.settings.promiseSaveWatchImmediately())
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(this.$t('lk.settings.resetWatchlistSuccessfully', {name: this.watchItemForRemove.name}))
            this.closeAttentionModal()
            this.$refs.page?.getData()
          }
        })
        .finally(() => {
          this.loadingRemoveList = false
        })
    },
    clearSelectedPairs() {
      // const isFuturesSelected = settings.selectedPairs.some((el) => el.label?.includes('.P'))

      // if (this.isFuturesActive) {
      //   if (!isFuturesSelected) {
      //     this.settings.selectedPairs = []
      //   }
      // } else {
      //   if (isFuturesSelected) {
      //     this.settings.selectedPairs = []
      //   }
      // }
    },
  }
}
</script>

<style lang="scss" scoped>
.link-group-wrap {
  padding: .375rem .875rem;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: .6875rem 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
    margin-bottom: 1rem;
  }
}

.c-card-body {
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 1.25rem;
  }
}

.button-play {
  padding: 0 .3rem;
  min-width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: .6875rem;
  margin-right: 2rem;

  @include media-breakpoint-up(md) {
    padding: 0 .375rem;
    min-width: 2rem;
    height: 2rem;
    font-size: .875rem;
    margin-right: .5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 1rem;
  }
}
</style>

