<template>
  <div class="d-flex justify-content-start align-items-center">
    <CButton
      v-for="(page, i) in pages"
      :key="i"
      :active="$route.name === page.name"
      :to="page.name"
      variant="ghost"
      class="button-custom shadow-none"
      color="dark"
    >
      <CIcon
        v-if="page.iconName"
        :name="page.iconName"
        class="m-0"
        style="fill:none"
      />

      <template v-if="page.label">
        {{ page.label }}
      </template>
    </CButton>

    <div class="spinner-wrap">
      <CSpinner
        v-if="loading"
        class="spinner"
        color="primary"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkGroup',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    pages() {
      const allPages = [
        {
          name: 'tablesview',
          label: '',
          iconName: 'cil-list'
        },
        {
          name: 'treemap',
          label: '',
          iconName: 'treemap'
        },
        {
          name: 'graphsview',
          label: '',
          iconName: 'cil-graph'
        },
        {
          name: 'trendsview',
          label: 'TRENDS',
          iconName: ''
        },
      ]
      return allPages
      // return this.$store.getters['user/getPermissions']('view_heat_map')
      //   ? allPages
      //   : allPages.filter(page => page.name !== 'treemap')
    }
  }
}
</script>

<style lang="scss" scoped>
.button-custom {
  padding: 0 .3rem;
  min-width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  flex-shrink: 0;
  font-size: .6875rem;

  @include media-breakpoint-up(md) {
    padding: 0 .375rem;
    min-width: 2rem;
    height: 2rem;
    font-size: .875rem;
  }

  &+& {
    margin-left: .25rem;

    @include media-breakpoint-up(md) {
      margin-left: .5rem;
    }
  }
}

.spinner-wrap {
  width: .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .25rem;
  margin-right: .25rem;

  /deep/ div {
    width: .75rem;
    height: .75rem;
  }

  @include media-breakpoint-up(md) {
    width: 1rem;
    margin-left: .5rem;
    margin-right: .5rem;

    /deep/ div {
      width: 1rem;
      height: 1rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-right: 0;
  }
}
</style>

