var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "flex-grow-1 overflow-hidden d-flex flex-column" },
        [
          _c(
            "div",
            { staticClass: "link-group-wrap d-flex align-items-center" },
            [
              _c("link-group", { attrs: { loading: _vm.loading } }),
              _c("portal-target", {
                attrs: { name: "screener-header", multiple: "" },
              }),
              _c(
                "CButton",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.$t("screener.playPauseBtn"),
                        container: ".c-app",
                      },
                      expression:
                        "{\n          content: $t('screener.playPauseBtn'),\n          container: '.c-app',\n        }",
                    },
                  ],
                  staticClass: "button-play shadow-none",
                  attrs: {
                    color: _vm.isPaused ? "warning" : "primary",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.isPaused = !_vm.isPaused
                    },
                  },
                },
                [
                  _c("CIcon", {
                    staticClass: "m-0",
                    attrs: {
                      name: _vm.isPaused ? "cil-media-play" : "cil-media-pause",
                    },
                  }),
                ],
                1
              ),
              _c("v-select-wrap", {
                staticClass: "ml-4 flex-grow-1",
                attrs: {
                  placeholder: "Ticker",
                  "coin-list": _vm.sortedPairs,
                  "futures-list": _vm.settings.futuresList,
                  "watch-lists": _vm.watchLists,
                  "search-keys": ["label", "labelAlt"],
                  "reset-watch-list": _vm.openAttentionModal,
                  "is-futures": _vm.isFuturesActive,
                  "disabled-option-key": "disabledOnScreener",
                },
                on: { dropdownIsOpened: _vm.getFreshWatchLists },
                model: {
                  value: _vm.settings.selectedPairs,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "selectedPairs", $$v)
                  },
                  expression: "settings.selectedPairs",
                },
              }),
            ],
            1
          ),
          _c(
            "CCard",
            { staticClass: "overflow-hidden flex-grow-1 d-flex m-0" },
            [
              _c(
                "CCardBody",
                { staticClass: "c-card-body d-flex flex-grow-1" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c("router-view", {
                        ref: "page",
                        attrs: {
                          settings: _vm.settings,
                          isPaused: _vm.isPaused,
                        },
                        on: {
                          changeLoading: function ($event) {
                            _vm.loading = $event
                          },
                          openGraph: _vm.openGraphHandler,
                          closeGraph: _vm.closeGraphHandler,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("screener.deleteWatchlistModal.title", {
              name:
                _vm.watchItemForRemove && _vm.watchItemForRemove.name
                  ? _vm.watchItemForRemove.name
                  : "",
            }),
            centered: "",
            show: !!_vm.watchItemForRemove,
          },
          on: { "update:show": _vm.closeAttentionModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            loading: _vm.loadingRemoveList,
                            disabled: _vm.loadingRemoveList,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.resetWatchList.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.yes")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: { click: _vm.closeAttentionModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.no")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("screener.deleteWatchlistModal.subtitle"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }