var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-start align-items-center" },
    [
      _vm._l(_vm.pages, function (page, i) {
        return _c(
          "CButton",
          {
            key: i,
            staticClass: "button-custom shadow-none",
            attrs: {
              active: _vm.$route.name === page.name,
              to: page.name,
              variant: "ghost",
              color: "dark",
            },
          },
          [
            page.iconName
              ? _c("CIcon", {
                  staticClass: "m-0",
                  staticStyle: { fill: "none" },
                  attrs: { name: page.iconName },
                })
              : _vm._e(),
            page.label ? [_vm._v(" " + _vm._s(page.label) + " ")] : _vm._e(),
          ],
          2
        )
      }),
      _c(
        "div",
        { staticClass: "spinner-wrap" },
        [
          _vm.loading
            ? _c("CSpinner", {
                staticClass: "spinner",
                attrs: { color: "primary", size: "sm" },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }